import { useEffect, useState } from 'react';
import './static/sass/main.scss';
import $ from 'jquery';
import { validateEmail } from './utils/validation';
import GoogleButton from 'react-google-button';

function App() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRequestId,setOtpRequestId] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username,setUsername]=useState('');
  const credentialstype = process.env.REACT_APP_CREDENTIAL_TYPE;
  

  $("#forgotOtpBackBtn").click(() => {
        $("#forgotOtp").hide();
        $("#forgotEmail").show();
    });

  const getCSRF = () => {
    fetch(process.env.REACT_APP_NEW_IDENTITY_CSRF, {
      credentials: credentialstype,
    })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      setCsrfToken(csrfToken);
      console.log(csrfToken);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getSession = () => {
    fetch(process.env.REACT_APP_NEW_IDENTITY_SESSION, {
      credentials: credentialstype,
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setUsername(data.user);
        $('#userLoggedIn').show();
      } else {
        $("#forgotEmail").show();
      }
      getCSRF();
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const sendOtp = (e)=>{
    e.preventDefault();
        $('#forgotEmail #spinnerLoader').removeClass('d-none');
        if(!validateEmail($('#forgotEmailInput').val())){
            $('#forgotEmail #spinnerLoader').addClass('d-none');
            $('#emailErrorDiv').removeClass('d-none');
            setTimeout(() => {
                $('#emailErrorDiv').addClass('d-none');
            }, 2000);
            return false;
        }
        const formData = new FormData();
        formData.append('email', email);
        fetch(process.env.REACT_APP_NEW_IDENTITY_SEND_OTP, {
        method: 'POST',
        headers: {
            "X-CSRFToken":csrfToken,
        },
        credentials: credentialstype,
        body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the response as JSON
        })
        .then((data) => {
            console.log(data);
            setOtpRequestId(data.otp_request_id);
            $("#forgotEmail").hide();
            $("#forgotOtp").show();
        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            $('#emailErrorDiv p').text(error);
            $('#emailErrorDiv').removeClass('d-none');
            setTimeout(() => {
                $('#emailErrorDiv').addClass('d-none');
            }, 2000);
        });
        $('#forgotEmail #spinnerLoader').addClass('d-none');
  }

  const verifyOtp = (e)=>{
    e.preventDefault();
    $('#forgotOtp #spinnerLoader').removeClass('d-none');
    if(otp === ''){
        $('#forgotOtpError').text('Otp should not be Empty');
            $('#forgotOtpError').removeClass('d-none');
            setTimeout(() => {
                $('#forgotOtpError').addClass('d-none');
        }, 2000);
        return false;
    }
    const formData = new FormData();
    formData.append('email', email);
    formData.append('entered_otp', otp);
    formData.append('otp_request_id', otpRequestId);
    fetch(process.env.REACT_APP_NEW_IDENTITY_VERIFY_OTP, {
    method: 'POST',
    headers: {
        "X-CSRFToken":csrfToken,
    },
    credentials: credentialstype,
    body: formData,
    })
    .then((response) => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response as JSON
    })
    .then((data) => {
        console.log(data);
        $('#forgotOtp #spinnerLoader').addClass('d-none');
        if(data.is_verified === true){
            var urlParams = new URLSearchParams(window.location.search);
            var param1 = urlParams.get('next');
            console.log(param1);
            setOtp('');
            setEmail('');
            setOtpRequestId('');
            let urlregex =  /^https:\/\/[a-zA-Z]+\.analyticsvidhya\.com\/.*$/;
            if(param1) {
                if(param1.match(urlregex)){
                    window.location.href = param1;
                }
                else{
                    window.location.href = 'https://two.analyticsvidhya.com';
                }
            }
            $("#forgotOtp").hide();
            $('#userLoggedIn').show();
            setUsername(data.user);
            getCSRF();
            console.log("set after login");
            // window.location.href = 'https://analyticsvidhya.com';
        }
        else{
            setOtp('');
            $('#forgotOtpError').text(data.response);
            $('#forgotOtpError').removeClass('d-none');
            setTimeout(() => {
                $('#forgotOtpError').addClass('d-none');
            }, 2000);
        }
    })
    .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setOtp('');
        $('#forgotOtpError').text('There was a problem with the fetch operation:', error);
            $('#forgotOtpError').removeClass('d-none');
            setTimeout(() => {
                $('#forgotOtpError').addClass('d-none');
        }, 2000);
    });
    $('#forgotOtp #spinnerLoader').addClass('d-none');
  }

  const logout = (e)=>{
    fetch(process.env.REACT_APP_NEW_IDENTITY_LOGOUT, {
        credentials: credentialstype,
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const openGoogleLoginPage = () => {
    const googleAuthUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;
  
    // const scope ='https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
  
    const params = {
      next  : window.location.href
    };

  
    const urlParams = new URLSearchParams(params).toString();
  
    window.location = `${googleAuthUrl}?${urlParams}`;
  };

  const searchQuery = (e)=>{
    if($('#queryPlatform').val() === null){
        alert('Select a platform to run the Query');
        return false;
    }
    if($('#queryTextarea').val() === ''){
        alert('Query should not be Empty');
        return false;
    }
    const formData = new FormData();
    formData.append('query', $('#queryTextarea').val());
    formData.append('source', $('#queryPlatform').val());
    fetch(process.env.REACT_APP_NEW_IDENTITY_GET_BLOG_DATA, {
        method: 'POST',
        headers: {
            "X-CSRFToken":csrfToken,
        },
        credentials: credentialstype,
        
        body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the response as JSON
        })
        .then((data) => {
            console.log(data);
            alert('Successfully get response.Kindly check console for the Output.')
        })
        .catch((error) => {
            alert('There was a problem with the fetch operation:', error);
        });
  }


  useEffect(getSession,[credentialstype]);
  
  return (
    <>
        <section className="login-section">
            <div className="card login-page-card" id="forgotEmail">
                <div style={{zIndex: 9}} className="spinner-border text-primary position-absolute top-50 start-50 d-none" role="status" id="spinnerLoader">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="card-header mt-4 border-0 text-center">
                    <img loading="lazy" src="https://av-public-assets.s3.ap-south-1.amazonaws.com/logos/av-logo-svg.svg" className="mx-auto"
                        alt="Analytics Vidhya"/>
                </div>
                <div className="card-body text-center">
                    <h6 className="small-text position-relative my-3">Please enter your email id</h6>
                    <div className="form-container">
                        <div className="d-none" id="forgotEmailError"></div>
                        <form id="popupForgotEmailForm">
                            <div className="input-group mb-5">
                                <span className="input-group-text" id="forgotEmailIcon">
                                    <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" >
                                        <title>email 2</title>
                                        <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Sign-in-with-email" transform="translate(-606.000000, -287.000000)"
                                                fill="#DFE2E6">
                                                <g id="email" transform="translate(606.000000, 287.000000)">
                                                    <path
                                                        d="M2,0 L18,0 C19.1046,0 20,0.8954 20,2 L20,14.00003 C20,15.10453 19.1046,16.00003 18,16.00003 L2,16.00003 C0.89543,16.00003 0,15.10453 0,14.00003 L0,2 C0,0.8954 0.89543,0 2,0 Z M10,7.00003 L18,2 L2,2 L10,7.00003 Z M2,14.00003 L18,14.00003 L18,4.37 L10,9.35863 L2,4.37 L2,14.00003 Z"
                                                        id="ic_mail"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <input type="email" className="form-control form-control-lg border-start-0"
                                    id="forgotEmailInput" placeholder="Enter Email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            </div>
                            <div className="nextbtn w-100 d-block mb-3" style={{clear:"both"}}>
                                <button className="btn btn-primary btn btn-primary w-100 mt-3 py-2" id="forgotEmailNextBtn" onClick={(e)=>{sendOtp(e)}}>
                                    Next
                                </button>
                            </div>
                            <hr />
                            <span className='text-muted mb-3 d-block'>or</span>
                            <GoogleButton
                                className='google-btn w-100 mb-4 rounded-1 overflow-hidden bg-primary'
                                onClick={openGoogleLoginPage}
                            />
                        </form>
                        <hr className='d-none' />
                        
                        <div id="emailErrorDiv" className="text-danger d-none">
                            <p>Email should not be empty or should correct</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card login-page-card" id="forgotOtp">
                <div style={{zIndex: 9}} className="spinner-border text-primary position-absolute top-50 start-50 d-none"
                    role="status" id="spinnerLoader">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="card-header mt-4 border-0 text-center">
                    <img loading="lazy" src="https://av-public-assets.s3.ap-south-1.amazonaws.com/logos/av-logo-svg.svg" className="mx-auto"
                        alt="Analytics Vidhya"/>
                </div>
                <div className="card-body text-center">
                    <h6 className="small-text position-relative my-3 mx-5">
                        <span className="back-btn pointer back-btn-forgot" id="forgotOtpBackBtn">
                            <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                <title>back</title>
                                <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Sign-in-with-email" transform="translate(-515.000000, -208.000000)"
                                        fill="#000000" fillRule="nonzero">
                                        <path
                                            d="M534.241255,214.784092 L517.576799,214.784092 L522.999138,209.31117 C523.149094,209.170033 523.23519,208.974209 523.237836,208.768303 C523.240483,208.562597 523.159329,208.364478 523.013075,208.219656 C522.866822,208.074817 522.667998,207.995604 522.462305,208.000188 C522.256599,208.004775 522.061479,208.092634 521.921743,208.243823 L515.219468,215.009327 C515.07886,215.151344 515,215.343113 515,215.54298 C515,215.742847 515.07886,215.934634 515.219468,216.076633 L521.921743,222.842137 C522.06147,222.993331 522.25659,223.081187 522.462305,223.085771 C522.668012,223.090358 522.866836,223.011148 523.013075,222.866304 C523.159329,222.721464 523.240483,222.52334 523.237836,222.317656 C523.23519,222.111774 523.149099,221.915949 522.999138,221.77479 L517.576799,216.301868 L534.241255,216.301868 C534.512237,216.301868 534.762759,216.157205 534.89825,215.92239 C535.033917,215.687752 535.033917,215.398402 534.89825,215.163778 C534.762759,214.928963 534.512241,214.7843 534.241255,214.7843 L534.241255,214.784092 Z"
                                            id="back"></path>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Please enter the OTP that is sent your email id
                    </h6>
                    <div className="form-container">
                        <div className="d-none text-danger mb-2" id="forgotOtpError"></div>
                        <form id="popupForgotOTPForm">
                            <div className="input-group mb-5">
                                <span className="input-group-text">
                                    <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <title>email 2</title>
                                        <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Sign-in-with-email" transform="translate(-606.000000, -287.000000)"
                                                fill="#DFE2E6">
                                                <g id="email" transform="translate(606.000000, 287.000000)">
                                                    <path
                                                        d="M2,0 L18,0 C19.1046,0 20,0.8954 20,2 L20,14.00003 C20,15.10453 19.1046,16.00003 18,16.00003 L2,16.00003 C0.89543,16.00003 0,15.10453 0,14.00003 L0,2 C0,0.8954 0.89543,0 2,0 Z M10,7.00003 L18,2 L2,2 L10,7.00003 Z M2,14.00003 L18,14.00003 L18,4.37 L10,9.35863 L2,4.37 L2,14.00003 Z"
                                                        id="ic_mail"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <input type="tel" className="form-control form-control-lg border-start-0" id="forgotOtpInput"
                                    placeholder="Enter OTP" value={otp} onChange={(e)=>{setOtp(e.target.value)}} />
                            </div>
                            <div className="nextbtn w-100 d-block mb-5" style={{clear:'both'}}>
                                <button className="btn btn-primary btn btn-primary w-100 mt-3 py-2" id="otpNextBtn" onClick={(e)=>{verifyOtp(e)}}>
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card login-page-card" id="userLoggedIn">
                <div style={{zIndex: 9}} className="spinner-border text-primary position-absolute top-50 start-50 d-none"
                    role="status" id="spinnerLoader">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="card-header mt-4 border-0 text-center">
                    <img loading="lazy" src="https://av-public-assets.s3.ap-south-1.amazonaws.com/logos/av-logo-svg.svg" className="mx-auto"
                        alt="Analytics Vidhya"/>
                </div>
                <div className="card-body text-center">
                    <h6 className="small-text position-relative my-3 mx-5">
                        Welcome to Analytics Vidhya
                    </h6>
                    <p>You have login as {username}</p>
                    <div className="px-5">
                        <button type="button" className="btn btn-primary btn btn-primary w-100 mt-3 py-2" data-bs-toggle="modal" data-bs-target="#dataModal">
                            Get Query Data
                        </button>
                        <button className="btn btn-primary btn btn-primary w-100 mt-3 py-2 mb-5" onClick={()=>{logout()}}>
                            Logout
                        </button> 
                    </div>
                </div>
            </div>
        </section>
        <div className="modal fade" id="dataModal" tabIndex="-1" aria-labelledby="dataModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header border-0">
                    <img loading="lazy" src="https://av-public-assets.s3.ap-south-1.amazonaws.com/logos/av-logo-svg.svg"
                        alt="Analytics Vidhya"/>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div class="input-group mb-3">
                            <select class="custom-select w-100 p-2 rounded-2 bg-white" id="queryPlatform">
                                <option selected disabled>Platform</option>
                                <option value="newmarketingpages">New Marketing Pages</option>
                                <option value="newleaderboard">New Leaderboard</option>
                                <option value="newcompetition">New Competitions</option>
                                <option value="newblog">New Blogs</option>
                                <option value="newcustomforms">New Custom Form</option>
                                <option value="newevents">New Events</option>
                            </select>
                        </div>
                        <div className="form-floating">
                            <textarea className="form-control" placeholder="Leave a comment here" id="queryTextarea"></textarea>
                            <label htmlFor="queryTextarea">Query</label>
                        </div>
                    </form>
                </div>
                <div className="modal-footer border-0">
                    <button type="button" className="btn btn-primary" onClick={searchQuery}>Search</button>
                </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default App;
